import React from "react";
import { Modal } from "@mui/material";
import Spinner from "./Spinner";

function LoadingBackdrop({ open }) {
  return (
    <Modal
      open={open}
      aria-labelledby="progress-modal"
      aria-describedby="making request"
    >
      <Spinner />
    </Modal>
  );
}

export default LoadingBackdrop;
