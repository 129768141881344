import { createApi } from "@reduxjs/toolkit/query/react";
import { Endpoints, RtkqTagEnum } from "utils/constants";
import Http from "./Http";

export const abdubelQueryApi = createApi({
  reducerPath: "abdubel",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({ url: Endpoints.LOGIN, data, method: "post" }),
    }),
    getInvestor: builder.query({
      query: (params) => ({ url: Endpoints.INVESTORS, params }),
      providesTags: [{ type: RtkqTagEnum.INVESTORS }],
    }),
    getSingleInvestor: builder.query({
      query: (first_name) => ({ url: `${Endpoints.INVESTORS}/${first_name}` }),
      providesTags: [{ type: RtkqTagEnum.INVESTORS }],
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: `${Endpoints.UPDATE_PASSWORD}`,
        data,
        method: "PUT",
      }),
      invalidatesTags: [{ type: RtkqTagEnum.AUTH_USER }],
    }),
    uploadMedia: builder.mutation({
      query: (formData) => ({
        url: Endpoints.UPLOAD_MEDIA,
        method: "POST",
        data:formData
      }),
      invalidatesTags: [{ type: RtkqTagEnum.UPLOAD_MEDIA }],
    }),

    refreshAccessToken: builder.mutation({
      query: (token) => ({
        url: "/refreshToken",
        data: { token },
        method: "post",
      }),
    }),
  }),
});

[abdubelQueryApi].forEach((api) => {
  api.enhanceEndpoints({ addTagTypes: Object.values(RtkqTagEnum) });
});

/**
 *
 * @param {import("axios").AxiosRequestConfig} baseConfig
 */

export function axiosBaseQuery(baseConfig = {}) {
  return request;

  /**
   *
   * @param {import("axios").AxiosRequestConfig} config
   */
  async function request(config = {}) {
    const url = config.url
      ? (baseConfig.url || "") + config.url
      : baseConfig.url;
    try {
      const response = await Http.request({ ...baseConfig, ...config, url });

      return {
        ...response,
        data: response.data,
        message: response.data?.message || null,
        status: response.status || 200,
        meta: { request: response.request, response },
      };
    } catch (error) {
      return {
        error: error.response
          ? {
              message: "",
              status: error.response.status,
              data: error.response.data,
            }
          : {
              message: "Something went wrong",
              data: { message: "Something went wrong" },
            },
      };
    }
  }
}

export function invalidatesTags(tagType, options = {}) {
  const { ids = [] } = options;
  const result = [
    { type: tagType, id: "LIST" },
    ...ids.map((id) => ({ type: tagType, id })),
  ];

  return result;
}
