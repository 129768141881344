import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "store/storeAction";
import { abdubelQueryApi } from "apis/QueryApi";

export const globalInitialState = {
  authUser: null,
  isLoadingModal: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))
      .addMatcher(
        abdubelQueryApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            accessToken: payload.access_token,
            ...payload.profile,
          };
        }
      ),
});

export const { toggleLoadingModalAction } = globalSlice.actions;
export default globalSlice;

export function getGlobalSliceStorageState({ authUser } = {}) {
  return { authUser };
}
