import "./App.css";
import { Provider } from "react-redux";
import Router from "./router/Router";
import store from "store";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Router />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;

// server {
//     listen 80;
//     server_name crowdreturns.abdubel.com www.crowdreturns.abdubel.com;

//     location / {
//         return 301 https://$host$request_uri;
//     }
// }

// server {
//     listen 443 ssl;
//     server_name crowdreturns.abdubel.com www.crowdreturns.abdubel.com;
//     root /var/www/abdubel;
//     index index.html;
//     location / {
//         try_files $uri $uri/ /index.html;
//     }
// }
